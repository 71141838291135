import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import client from "../../Content";
import Masonry from "react-masonry-css";
import "../Blog.css";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import PropagateLoader from "react-spinners/PropagateLoader";
const contentful = require("contentful-management");

const Interviews = ({ locale }) => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [selectedType, setSelectedType] = useState("all");
  const [totalCount, setTotalCount] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState();
  const [randomValue1, setRandomValue1] = useState();
  const [randomValue2, setRandomValue2] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);

  async function getTotalEntryCount(contentTypeId) {
    try {
      // Use the Contentful Management API to get the total count
      const entries = await client.getEntries({
        content_type: contentTypeId,
        limit: 1,
      });

      return entries.total;
    } catch (error) {
      console.error("Error fetching entry count:", error);
      return null;
    }
  }
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    window.scrollTo(0,0)
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await client.getEntries({
          content_type: "interview",
          skip: (page - 1) * pageSize,
          limit: pageSize,
          query: search,
          locale: locale,
        });
console.log(response.items)
        setPosts(response.items);
        setLoading(false);
        // console.log(posts);
      } catch (error) {
        //console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };

    const fetchTotalCount = async () => {
      const totalCount = await getTotalEntryCount("interview");
      setTotalCount(totalCount);
    };

    const debouncedFetchPosts = debounce(fetchPosts, 0);

    debouncedFetchPosts();
    fetchTotalCount();

    console.log(posts[0]?.fields?.description?.content);
    return () => clearTimeout(debouncedFetchPosts);

  }, [page, pageSize, search, locale]);

  
  const handleSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1)
    //console.log(selectedType);
  };
  const getRandomOffset = (range) => {
    return Math.floor(Math.random() * range) + 30; // Adjust the range as needed
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  /* useEffect(() => {
    console.log("Total entry count:", totalCount);
  }, [totalCount]); */

  useEffect(() => {
    setRandomValue1(getRandomOffset(60));
    setRandomValue2(getRandomOffset(15));
  }, []);
  const { t, i18n } = useTranslation();

  const toggleButtonClick = () => {
    setButtonClicked(!buttonClicked);
  };
  const color = "#FFFFFF";
  const getShortDescription = (content) => {
    let description = '';
  
    if (content && Array.isArray(content)) {
      for (const item of content) {
        if (item.nodeType === 'paragraph' && item.content && item.content.length > 0) {
          let paragraphText = ''
          for(const elem of item.content){
            paragraphText += elem.value||'' 
          } ;
          description += paragraphText;
        }else{
          getShortDescription(item)
        }
      }
    }
    description = description.slice(0,300) + '...'
    return description;
  };
  const updateEntry = async (entryId, currentViews) => {
    const client = contentful.createClient({
      accessToken: "CFPAT-zSrWqMmOLvoeQ0AXvVVwi-eU82How1GRBvBbjU5sQgU",
    });
    try {
      // Fetch the entry
      await client
        .getSpace("5nwreyzf3wkj")
        .then((space) => space.getEnvironment("master"))
        .then((environment) => environment.getEntry(entryId))
        .then((entry) => {
          entry.fields.view["en-US"] = currentViews;
          return entry.update();
          //entry.publish();
        })
        .then((entry) => entry.publish());
      console.log("View count updated successfully");
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };
  return (
    <div>
      <div className="filter w-full flex flex-col md:flex-row items-center justify-between px-3">
       <div className="w-full flex justify-end">
       <div className="flex ">
          <input
            type="search"
            value={searchTerm}
            placeholder={t("search") + "..."}
            className="px-3 py-1 focus:outline-none bg-black rounded-0 text-white border border-solid border-white"
            onChange={(e) => {setSearchTerm(e.target.value);setButtonClicked(false)}}
          />
          <button
            onClick={() => {
              toggleButtonClick();
              !buttonClicked ? setSearch(searchTerm) : setSearch("");
              buttonClicked && setSearchTerm("");
              setLoading(true);
            }}
            className="searchButton bg-white text-black text-lg px-2 border border-solid border-white  py-1  focus:outline-none duration-200"
          >
            {!buttonClicked ? <FaSearch /> : <MdCancel />}
          </button>
        </div>
       </div>
      </div>
      <div className="min-h-[80vh]">
        {!loading ? (
          <Masonry
            breakpointCols={{ default: 4, 1100: 2, 700: 1 }}
            className="my-masonry-grid "
            columnClassName="my-masonry-grid_column"
          >
            {posts.map((post, index) => (
              <div
                key={index}
                className={`transition-all post  md:grayscale hover:grayscale-0 duration-300 blogCard md:border border-solid border-[#FAF0E6] hover:cursor-pointer flex flex-col items-center hover:scale-105`}
                onClick={() => {
                  navigate(`/interviews/${post.sys.id}`);
                  updateEntry(post.sys.id, post.fields.view + 1);
                }}
                style={{
                  height: "min-content",
                  marginTop:
                    index % 2 == 0
                      ? `${randomValue1}px`
                      : index % 2 != 0
                      ? `${randomValue2}px`
                      : "0",
                }}
              >
                <img
                  className=" "
                  src={post.fields.thumbnail.fields.file?.url}
                  alt="suzanblog.az thumbnail.jpg"
                />
                <div className="blogTitle p-4 bg-[#FAF0E6] text-black w-full">
                <div className="flex items-center justify-between">
                        <span className="text-xs">
                          {post.fields.date?.slice(0, 10)}
                        </span>
                        <div className="flex items-center text-sm font-bold justify-center ">
                          <i className="fa-solid fa-eye mr-1"></i>
                          <p className="mb-1">{post.fields.view}</p>
                        </div>
                      </div>
                  <p className="text-xl font-serif font-medium">
                    {post.fields.title}
                  </p>
                  <p>
                  {getShortDescription(post?.fields?.description?.content) + '...'}
                  </p>
                </div>
              </div>
            ))}
          </Masonry>
        ) : (
          <div className="w-full h-[80vh] flex items-center justify-center">
            <PropagateLoader
              color={color}
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>

      <div className="md:flex items-center w-full justify-between">
        <div className="pagination my-1 md:my-5 mx-3">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="rounded-[6px] px-2 font-bold text-white border-2 border-solid border-white h-[34px] text-[15px] bg-[#4b465c11]  mx-1 hover:bg-white hover:text-black disabled:opacity-50 focus:outline-none"
          >
            {t("prev")}
          </button>
          {Array.from({
            length: Math.ceil(totalCount / pageSize),
          }).map((_, index) => {
            const isCurrent = page === index + 1;
            const isWithinRange =
              index + 1 === 1 ||
              index + 1 === Math.ceil(totalCount / pageSize) ||
              Math.abs(index + 1 - page) <= 2;

            if (isCurrent || isWithinRange) {
              return (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={
                    isCurrent
                      ? "active focus:outline-none border-2 border-solid duration-200 border-white rounded-[6px] w-[34px] h-[34px]   text-black font-bold  text-[15px] bg-white mx-1"
                      : "rounded-[6px] focus:outline-none border-2 border-solid border-white  w-[34px] h-[34px]  duration-200 font-bold text-white text-[15px]  mx-1 hover:text-black  hover:bg-white"
                  }
                >
                  {index + 1}
                </button>
              );
            } else if (
              index === 1 ||
              index === Math.ceil(totalCount / pageSize) - 2
            ) {
              // Display ellipsis for page 2 and the second-to-last page.
              return (
                <span key={index} className="mx-1 text-white font-bold">
                  ...
                </span>
              );
            }
            return null;
          })}
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === Math.ceil(totalCount / pageSize)}
            className="rounded-[6px] px-2 font-bold text-white border-2 border-solid border-white h-[34px] text-[15px] bg-[#4b465c11]  mx-1 hover:bg-white hover:text-black disabled:opacity-50 focus:outline-none"
          >
            {t("next")}
          </button>
        </div>
        <div className="pageSize flex items-center md:p-2 px-4">
          <p className="text-white font-medium">{t("blogsPerPage")}</p>
          <select
            name="pageSize"
            id="pageSize"
            className="md:w-[60px] outline-none m-3 text-xl bg-black text-white hover:text-emerald"
            onChange={handleSizeChange}
            value={pageSize}
          >
            {[20, 30, 40].map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Interviews;
