import React, { useEffect, useState } from "react";
import client from "../Content";
import Masonry from "react-masonry-css";
const AboutPhotos = ({posts}) => {
  

  return (
    <div>
      <Masonry
        breakpointCols={{ default: 4, 1100: 2, 700: 1 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {posts?.map((post, index) => (
          <div
            key={index}
            className={`transition-all post  md:grayscale hover:grayscale-0 duration-300 blogCard md:border border-solid border-[#FAF0E6] hover:cursor-pointer flex flex-col items-center hover:scale-105`}
            style={{
              height: "max-content",
            }}
          >
            <img
              className=" "
              src={post.fields.file?.url}
              alt="Suzanblog.az about image"
            />
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default AboutPhotos;
