import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import "./navbar.scss";
import { useTranslation } from "react-i18next";
import { FaInstagram, FaYoutube } from "react-icons/fa";

function Navbar({setLocale}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };
  const { t, i18n } = useTranslation();
  function handleClick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <header className="header sticky top-0 bg-black z-10 ">
      <div className="header__content py-0 ">
        <div className="text-3xl md:text-4xl">
          <Link to="/" className="no-underline hover:text-white ">
            suzanblog</Link>
        </div>
        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 768 ? `${"isMenu"}` : ""} 
          }`}
        >
          <ul>
            <li>
              <Link
                className="tracking-widest font-bold"
                onClick={() => {
                  if (size.width < 768 && menuOpen) {
                    setMenuOpen(false);
                  }
                }}
                to="/"
              >
                {t("menu.home")}
              </Link>
            </li>
            <li>
              <Link
                className="tracking-widest font-bold"
                onClick={() => {
                  if (size.width < 768 && menuOpen) {
                    setMenuOpen(false);
                  }
                }}
                to="/interviews"
              >
                {t("menu.interviews")}
              </Link>
            </li>
            <li>
              <Link
                className="tracking-widest font-bold"
                onClick={() => {
                  if (size.width < 768 && menuOpen) {
                    setMenuOpen(false);
                  }
                }}
                to="/blog"
              >
                {t("menu.blog")}
              </Link>
            </li>

            <li>
              <Link
                className="tracking-widest font-bold"
                onClick={() => {
                  if (size.width < 768 && menuOpen) {
                    setMenuOpen(false);
                  }
                }}
                to="/about"
              >
                {t("menu.about")}
              </Link>
            </li>
            <li>
              <button
                className="focus:outline-none hover:text-[#ffed00] font-bold"
                onClick={() => {handleClick("az");setLocale('az')}}
                style={{ paddingRight: "5px" }}
              >
                Az
              </button>
              /
              <button
                className="focus:outline-none hover:text-[#ffed00] font-bold"
                onClick={() =>{ handleClick("en");setLocale('en-US')}}
                style={{ paddingLeft: "5px" }}
              >
                En
              </button>
            </li>
          </ul>
          <div className="flex sm:hidden items-center ">
          <a className="text-3xl mx-1 " target="_blank" href="https://www.youtube.com/channel/UCzYCjNvj1TKaehDdyRs33yw"><FaYoutube className="hover:text-[#ffed00] duration-200" /></a>
          <a className="text-3xl mx-1 " target="_blank" href="https://www.instagram.com/suzanblog.az/"><FaInstagram className="hover:text-[#ffed00] duration-200" /></a>
        </div>
        </nav>
        
        <div>
        <div className=" hidden sm:flex items-center ">
          <a className="text-3xl mx-1 " target="_blank" href="https://www.youtube.com/channel/UCzYCjNvj1TKaehDdyRs33yw"><FaYoutube className="hover:text-[#ffed00] duration-200" /></a>
          <a className="text-3xl mx-1 " target="_blank" href="https://www.instagram.com/suzanblog.az/"><FaInstagram className="hover:text-[#ffed00] duration-200" /></a>
        </div>
        </div>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
