import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import client from "../../Content";
import Swiper from "./Swiper";
import { FaFile } from "react-icons/fa";
import PropagateLoader from "react-spinners/PropagateLoader";


const Interview = ({locale}) => {
  const {id} = useParams();
  const [blog, setBlog] = useState();
  const [loading,setLoading] =useState(true)

  useEffect(() => {
    window.scrollTo(0,0)

    client
      .getEntry(id,{locale:locale})
      .then((entry) => {
        setBlog(entry);
        setLoading(false)
        //console.log(blog)
      })
      .catch((error) => {/* console.log(error);setLoading(false) */});

  }, [locale]);
  
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        const { contentType, fileName, url } = file;
  
        switch (contentType.split("/")[0]) {
          case "image":
            return <img className="my-2" src={url} alt={title} />;
          case "video":
            return (
              <video controls width="100%">
                <source src={url} type={contentType} />
                Your browser does not support the video tag.
              </video>
            );
          case "audio":
            return (
              <audio controls>
                <source src={url} type={contentType} />
                Your browser does not support the audio tag.
              </audio>
            );
          case "application":
            // Handle other file types, e.g., PDF, documents, etc.
            return (
              <a href={url} target="_blank" rel="noopener noreferrer" className="flex items-center my-2">
                <FaFile className="text-xl mr-2"/>{fileName || title}
              </a>
            );
          default:
            return <span>Unhandled {contentType} asset</span>;
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        const url = node.data.uri;
        const { value } = node.content[0];
        return <a target="_blank" href={url}>{value}</a>;
      },
    },
  };

  return (
    <>
      {!loading?(<div className="md:max-w-[1124px] bg-[#FAF0E6] border border-solid border-white text-black  mx-auto p-5">
        {blog && (
          <Swiper slides={blog?.fields?.slider} />
        )}
        <h3 className="text-black text-3xl font-serif my-3">
          {blog?.fields?.title}
        </h3>
        {blog && documentToReactComponents(blog?.fields?.description, options)}
        <iframe className="my-3 w-full h-auto md:min-h-[600px]"  src={blog?.fields?.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>):(
          <div className="w-full h-[80vh] flex items-center justify-center">
            <PropagateLoader
              color="#ffffff"
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
    </>
  );
};

export default Interview;
