import React from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const {t} = useTranslation()
  return (
    <div className="footer pt-4">
        <div className="container-fluid text-center">
          <p className='text-white'>
            Copyright &copy; 2024. All rights reserved 
          </p>
          <hr className='w-[80%] mx-auto h-[.5px] bg-gray-800 my-0' />
          <p className='text-gray-400  font-bold font-serif p-0 m-0'><a className='no-underline' target='_blank' href="https://www.linkedin.com/in/seymzr/">{t('signature')}</a></p>
        </div>
      </div>
  )
}

export default Footer