import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import client from "../Content";
import { FaFile } from "react-icons/fa";
import PropagateLoader from "react-spinners/PropagateLoader";
import commentClient from "../CommentContent";
import { FaRegUserCircle } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { IoIosHeart } from "react-icons/io";
import { IoIosHeartEmpty } from "react-icons/io";
import { toast } from "react-toastify";

const contentful = require("contentful-management");

const SingleBlog = ({ locale }) => {
  const { id } = useParams();
  const [blog, setBlog] = useState();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState();
  const [newComment, setNewComment] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [commentCreated, setCommentCreated] = useState(false);

  const getCurrentTime = () => {
    const now = new Date();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",

      timeZone: "Asia/Baku", // Adjust the timeZone as needed
    };

    const formattedTime = new Intl.DateTimeFormat("az", options)
      .format(now)
      .split(" ")
      .join("");

    console.log(formattedTime);
    setCurrentTime(formattedTime);
  };

  useEffect(() => {
    window.scrollTo(0,0)
    client
      .getEntry(id, { locale: locale })
      .then((entry) => {
        setBlog(entry);
        setLoading(false);
        //getCurrentTime();

        //console.log(blog)
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
    //getCurrentTime();
    const currentDateTime = new Date().toISOString();
    setNewComment({
      ...newComment,
      blogId: id,
      date: currentDateTime,
    });
  }, [locale]);
  const fetchComments = async () => {
    try {
      const response = await commentClient.getEntries({
        content_type: "comment",
        "fields.blogId": id,
      });
      console.log(response.items);
      setComments(response.items);
      //console.log(comments);
    } catch (error) {}
  };
  useEffect(() => {
    fetchComments();
  }, []);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        const { contentType, fileName, url } = file;

        switch (contentType.split("/")[0]) {
          case "image":
            return <img src={url} alt={title} />;
          case "video":
            return (
              <video controls width="100%">
                <source src={url} type={contentType} />
                Your browser does not support the video tag.
              </video>
            );
          case "audio":
            return (
              <audio controls>
                <source src={url} type={contentType} />
                Your browser does not support the audio tag.
              </audio>
            );
          case "application":
            // Handle other file types, e.g., PDF, documents, etc.
            return (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center my-2"
              >
                <FaFile className="text-xl mr-2" />
                {fileName || title}
              </a>
            );
          default:
            return <span>Unhandled {contentType} asset</span>;
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        const url = node.data.uri;
        const { value } = node.content[0];
        return (
          <a target="_blank" href={url}>
            {value}
          </a>
        );
      },
    },
  };
  const handleInputChange = (e, key) => {
    console.log(newComment);
    setNewComment({
      ...newComment,
      [key]: e.target.value,
    });
  };

  const postComment = () => {
    const commentClient = contentful.createClient({
      accessToken: "CFPAT-ezEimg0AL5PA_Z_8Sso72BzQcJxiohD0EmbQMVqh2aw",
    });
    commentClient
      .getSpace("fne3yvi2mcq0")
      .then((space) => space.getEnvironment("master"))
      .then((environment) =>
        environment.createEntry("comment", {
          fields: {
            username: {
              "en-US": newComment?.username,
            },
            date: {
              "en-US": newComment?.date,
            },
            blogId: {
              "en-US": newComment?.blogId,
            },
            comment: {
              "en-US": newComment?.comment,
            },
          },
        })
      )
      .then((entry) => {
        entry.publish();
        toast.success("Comment created successfully!");
        setTimeout(fetchComments, 1500);
      })
      .catch(console.error);
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="md:max-w-[1124px] border border-solid border-white bg-[#FAF0E6] text-black mx-auto p-5">
            {blog && (
              <img
                src={blog.fields.thumbnail.fields.file.url}
                alt="suzanblog.az thumbnail.jpg"
                className="mx-auto "
              />
            )}
            <h3 className=" text-3xl font-serif font-bold my-3">
              {blog?.fields?.title}
            </h3>
            <div className="">
              {blog &&
                documentToReactComponents(blog.fields.description, options)}
            </div>
          </div>
          <div className="w-[90%] md:max-w-[1124px] mx-auto my-4 text-white border border-solid border-white/30 px-2 py-4 ">
            <h2 className="text-xl text-white font-serif font-medium ">
              Comments
            </h2>
            <div className="addComment my-1 mt-3 flex flex-col justify-center items-start">
              <input
                onChange={(e) => {
                  handleInputChange(e, "username");
                }}
                value={newComment?.username ?? ""}
                type="text"
                placeholder="Enter Your Username"
                required={true}
                className=" w-[300px] py-1 mb-1 px-3 bg-black border border-solid border-zinc-400 focus:outline-none text-white   "
              />
              <textarea
                value={newComment?.comment ?? ""}
                onChange={(e) => {
                  handleInputChange(e, "comment");
                }}
                required={true}
                placeholder="Write a Comment"
                className="w-[300px] py-1 px-3 bg-black border border-solid border-zinc-400 focus:outline-none text-white  "
                rows="2"
              ></textarea>
              <button
                onClick={() => postComment()}
                className="bg-white text-black font-medium px-3 py-1 my-2 "
              >
                Comment
              </button>
            </div>

            <div className="commentList">
              {comments?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center  border-dotted border-2 border-white p-2 my-2"
                  >
                    <p className="font-serif text-3xl rounded-full p-2 flex items-center justify-center bg-white text-black">
                      <FaRegUserCircle />
                    </p>
                    <div className="ml-2">
                      <p className="flex items-center">
                        <MdDateRange className="mr-2" />
                        {item?.fields?.date?.slice(0, 10)}
                      </p>
                      <p className="">{item.fields.comment}</p>
                      <p>@{item.fields.username}</p>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="w-full h-[80vh] flex items-center justify-center">
          <PropagateLoader
            color="#ffffff"
            loading={loading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default SingleBlog;
