import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Switch, Routes, useLocation } from "react-router-dom";
import Index from "./pages/Index";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import Interviews from "./pages/Interviews/Interviews";
import Interview from "./pages/Interviews/Interview";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { FaArrowUp } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import Welcome from "./Welcome";
import { ToastContainer } from "react-toastify";
import client from './Content'


import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [locale, setLocale] = useState("az");
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [aboutMe,setAboutMe] = useState()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 700) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Set loading to false after 2 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1500);

    // Cleanup the timeout in case the component unmounts before the timeout
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    client
      .getEntry('3FS8yMDJfUy9he0kjBHl3y', { locale: locale })
      .then((entry) => {
        setAboutMe(entry.fields.about.content[0].content[0].value);
        console.log(entry)
        
      })
      .catch((error) => {
        //console.log(error);
      });

     
    window.scrollTo(0, 0);
  }, [locale]);

  return (
    <>
      {loading ? (
        <Welcome />
      ) : (
        <Router>
          <Navbar setLocale={setLocale} />
          <Routes>
            <Route path="/" exact element={<Index locale={locale} about={aboutMe} />} />
            <Route path="/about" element={<About locale={locale} about={aboutMe} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog locale={locale} />} />
            <Route path="/blog/:id" element={<SingleBlog locale={locale} />} />
            <Route
              path="/interviews"
              element={<Interviews locale={locale} />}
            />
            <Route
              path="/interviews/:id"
              element={<Interview locale={locale} />}
            />
          </Routes>
          <Footer />
          {showButton && (
            <button
              onClick={scrollToTop}
              className="fixed focus:outline-none bottom-[30px] right-[30px]  py-2 px-2 bg-white  text-black  hover:bg-black hover:text-white duration-300 "
            >
              <IoIosArrowUp />
            </button>
          )}
        </Router>
      )}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
