import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect, useState } from "react";



export default function Welcome({ delay }) {
  const baseText = "suzanblog";
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) =>
    baseText.slice(0, latest)
  );

  useEffect(() => {
    const controls = animate(count, baseText.length, {
      type: "tween",
      delay: delay,
      duration: 1.5,
      ease: "easeInOut",
      
    });
    return controls.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div className="w-full font-serif text-white h-[100vh] flex items-center justify-center text-7xl">
        <motion.span>{displayText}</motion.span>
      </div>
  );
}
