import React, { useRef } from "react";
import "../assets/css/style.css";
import { useTranslation } from "react-i18next";
import emailjs, { send } from 'emailjs-com'
import {toast} from 'react-toastify'
function Contact() {
  
  const {t,i18n} = useTranslation();
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_zxfobps',
      'template_y3mwrwn',
      form.current,
      'lAUrW2sSfx5r3-SY7',
    ).then(
      result=>{
        toast.success('Message sent successfully')},
      error=>{toast.error("There was a error while sending your message.Please contact with Suzan with other options or refresh the page and try again.")}
    )
    
}
  return (
    <div className="site-wrap">
      <div className="site-section" data-aos="fade">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="row mb-5">
                <div className="col-12">
                  <h2 className="site-section-heading text-center font-serif">{t('contact')}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto mb-5">
              <form action="#" ref={form}>
                <div className="row form-group">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-white" htmlFor="fname">
                      {t('email.firstName')}
                    </label>
                    <input className="form-control" name="name" id="fname" type="text" />
                  </div>
                  <div className="col-md-6">
                    <label className="text-white" htmlFor="lname">
                    {t('email.lastName')}
                    </label>
                    <input className="form-control" name="surname" id="lname" type="text" />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-white" htmlFor="email">
                    {t('email.email')}
                    </label>
                    <input className="form-control" name="email" id="email" type="email" />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-white" htmlFor="subject">
                    {t('email.subject')}
                    </label>
                    <input
                      className="form-control"
                      id="subject"
                      type="subject"
                      name="subject"
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-white" htmlFor="message">
                    {t('email.message')}
                    </label>
                    <textarea
                      className="form-control"
                      cols={30}
                      id="message"
                      name="message"
                      placeholder=""
                      rows={7}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                   <button type="button" onClick={sendEmail} className="border border-solid  border-[#ffed00] py-1 px-3 font-medium hover:bg-[#ffed00] hover:text-black">
                    {t('email.submit')}
                   </button>
                  </div>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
