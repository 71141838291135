import React, { useEffect, useState } from "react";
import image1 from "../assets/images/photo/about.jpeg";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import blog from "../assets/images/blog.jpg";
import interview from "../assets/images/interview.jpg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import client from "../Content";
import PropagateLoader from "react-spinners/PropagateLoader";

const Index = ({ locale,about }) => {
  const [blogDesc,setBlogDesc] = useState();
  const [interviewDesc,setInterviewDesc] = useState();
const [loading,setLoading] = useState(true)
  useEffect(() => {
    client
      .getEntry('4oHHFdZWbNyrw94Mej2tKm', { locale: locale })
      .then((entry) => {
        setBlogDesc(entry);
        setLoading(false);
        console.log(blogDesc)
        
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });

      client
      .getEntry('Ey488X2WoIiY3n2tXI4Qs', { locale: locale })
      .then((entry) => {
        setInterviewDesc(entry);
        setLoading(false);
        
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [locale]);
  const { t, i18n } = useTranslation();
  return (
    <>
    {
      loading?(<div className="w-full h-[80vh] flex items-center justify-center">
      <PropagateLoader
        color={'white'}
        loading={loading}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>):(<div className="overflow-hidden">
      <div className="site-wrap px-3">
        <div>
          <div className="blogIndex flex flex-col md:flex-row w-full items-center justify-center grayscale hover:grayscale-0 duration-300 my-5 relative">
            <div className="textBox w-full md:w-1/2 flex flex-col justify-end">
              <div className="w-full h-full object-cover"></div>
              <p
                
                className="border-b-4 text-start pl-3 pb-3 text-white sm:m-3 md:m-0 md:self-end font-bold md:absolute md:bottom-24 border-solid border-white text-4xl md:text-7xl w-[45%]"
              >
                {blogDesc?.fields.title}
              </p>
            </div>
            <div
              
              className="relative w-full md:w-1/2 "
            >
              <img
                src={blog}
                alt="blog.jpg"
                className="w-full h-full object-cover my-3 md:my-0"
              />
              <p className="border-b-4 hidden md:block absolute bottom-4 md:bottom-24 border-solid border-black text-4xl md:text-7xl w-[70%]">
                &nbsp;
              </p>
            </div>
          </div>
          <p
            
            className="w-full md:w-1/2 px-5 pb-5 text-white text-xl  md:-mt-24 md:ml-5"
          >
            {blogDesc?.fields.description.content[0].content[0].value}
          </p>
        </div>

        <div
          
        >
          <div className="blogIndex flex flex-col md:flex-row w-full items-center justify-center grayscale hover:grayscale-0 duration-300 my-5 relative">
            <div className="textBox block sm:hidden w-full md:w-1/2 flex flex-col justify-end">
              <div className="w-full h-full object-cover"></div>
              <p className="border-b-4  pl-3 pb-3 text-white sm:m-3 md:m-0 md:self-end font-bold md:absolute md:bottom-24 border-solid border-white text-4xl md:text-7xl w-[50%]">
                {interviewDesc?.fields.title}
              </p>
            </div>
            <div className="relative w-full md:w-1/2  flex flex-col justify-end">
              <img
                src={interview}
                alt="interview.jpg"
                className="w-full h-full object-cover my-3 md:my-0"
              />
              <p className="border-b-4 hidden md:block md:absolute  md:bottom-24 border-solid md:right:0 self-end border-black text-4xl md:text-7xl w-[70%]">
                &nbsp;
              </p>
            </div>
            <div className="textBox hidden sm:block w-full md:w-1/2 flex flex-col justify-start">
              <div className="w-full h-full object-cover"></div>
              <p className="border-b-4   text-start pl-5 pb-3 text-white sm:m-3 md:m-0 md:self-start font-bold md:absolute md:bottom-24 border-solid border-white text-4xl md:text-7xl w-[45%]">
                {interviewDesc?.fields.title}
              </p>
            </div>
          </div>
          <div className="w-full md:flex justify-end -mt-5">
            <p className="w-full md:w-1/2 px-5 pb-5 text-white text-xl  md:text-end md:-mt-24 md:mr-5">
            {interviewDesc?.fields.description.content[0].content[0].value}
            </p>
          </div>
        </div>


        <div>
          <div className="blogIndex flex flex-col md:flex-row w-full items-center justify-center grayscale hover:grayscale-0 duration-300 my-5 relative">
            <div className="textBox w-full md:w-1/2 flex flex-col justify-end">
              <div className="w-full h-full object-cover"></div>
              <p
                
                className="border-b-4 text-start pl-3 pb-3 text-white sm:m-3 md:m-0 md:self-end font-bold md:absolute md:bottom-24 border-solid border-white text-4xl md:text-7xl w-[45%]"
              >
                {t('aboutme')}
              </p>
            </div>
            <div
              
              className="relative w-full md:w-1/2 "
            >
              <img
                src={image1}
                alt="blog.jpg"
                className="w-full h-full object-cover my-3 md:my-0"
              />
              <p className="border-b-4 hidden md:block absolute bottom-4 md:bottom-24 border-solid border-black text-4xl md:text-7xl w-[70%]">
                &nbsp;
              </p>
            </div>
          </div>
          <p
            
            className="w-full md:w-1/2 px-5 text-white text-xl  md:-mt-24 md:ml-5"
          >
            {about.slice(0,300)+'...'}
          </p>
          <div className="w-[50%]  flex items-center justify-end">
          <Link
              to="/about"
              className="px-2 text-white no-underline my-2 border-2 border-solid border-white py-1 self-start font-medium  hover:bg-white hover:border-white hover:text-white duration-200"
            >
              {t('readMore')}
            </Link>
          </div>
        </div>
        {/* <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="h-[600px] w-full md:w-[80%]  duration-300 ease-linear md:grayscale hover:grayscale-0">
            <img
              src={image1}
              alt="suzanblog.az about1.jpg"
              className="h-[600px] object-cover w-full"
            />
          </div>
          <div className="h-[600px] w-full md:w-[80%] px-5  duration-300 ease-linear bg-[#FAF0E6] flex items-center justify-center flex-col">
            <p className="text-lg text-black font-medium ">
              {about.slice(0,300)+'...'}
            </p>
            <Link
              to="/about"
              className="px-2 no-underline py-1 border-2 border-solid border-black self-start font-medium my-2 hover:bg-white hover:border-white hover:text-white duration-200"
            >
              {t('readMore')}
            </Link>
          </div>
        </div> */}
      </div>
    </div>)
    }
    </>
  );
};

export default Index;
