import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Contact from "./Contact";
import client from "../Content";
import { motion } from "framer-motion";
import AboutPhotos from "./AboutPhotos";
import PropagateLoader from "react-spinners/PropagateLoader";

function About({about}) {
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState();
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await client.getEntries({
        content_type: "aboutSectionPhotos",
      });
      console.log(response.items[0].fields.img);
      setPosts(response.items[0].fields.img);
      setLoading(false);
      // console.log(posts);
    } catch (error) {
      setLoading(false);
      //console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0,0)
    fetchPosts();

  }, []);
  const { t, i18n } = useTranslation();

  return (
    <>
      {loading ? (
        <div className="w-full h-[80vh] flex items-center justify-center">
        <PropagateLoader
          color={'white'}
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      ) : (
        <>
          <div className="flex items-center flex-col lg:flex-row bg-[#FAF0E6] m-3 md:m-5 p-4 overflow-hidden">
            <div>
              <motion.h2
                initial={{ x: -150 }}
                whileInView={{ x: 0 }}
                transition={{ ease: "linear", duration: 0.9 }}
                viewport={{ once: true }}
                className="text-3xl font-serif text-black mb-3 font-bold"
              >
                {t("aboutme")}
              </motion.h2>
              <motion.p
                initial={{ x: 150 }}
                whileInView={{ x: 0 }}
                transition={{ ease: "linear", duration: 0.9 }}
                viewport={{ once: true }}
                className="text-black font-sans-serif"
              >
                {about}
              </motion.p>
              {/* <a href="mailto:nasirovasuzan@gmail.com">
          <i className="fa-solid fa-envelope text-[30px] my-2 ml-2"></i>
        </a> */}
            </div>
            <div className="flex flex-col lg:flex-row items-center h-full w-full">
              <motion.div
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeInOut", duration: 0.9 }}
                className="w-[300px] h-[400px]  about1 bg-center bg-cover  grayscale hover:grayscale-0 duration-200 m-2"
              ></motion.div>
              <motion.div
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeInOut", duration: 0.9 }}
                className="w-[300px] h-[400px] about2 bg-center bg-cover  grayscale hover:grayscale-0 duration-200 m-2"
              ></motion.div>
            </div>
          </div>
          <AboutPhotos posts={posts} />
          <Contact />
        </>
      )}
    </>
  );
}

export default About;
