import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './swiper.css'
export default ( {slides}) => {
  return (
    <Swiper
    modules={[Autoplay,Navigation, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      draggable={true}
      centeredSlides={true}
      //onSwiper={(swiper) => console.log(swiper)}
      navigation
      centeredSlidesBounds={true}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      
    >
        {
      slides.map((item,index)=>(
        <SwiperSlide>
            <img src={item.fields.file.url} alt={item.fields.url} />
        </SwiperSlide>
      ))
        }
    </Swiper>
  );
};