import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './i18n.js'
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./service-worker.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <div >
      <App />
    </div>
  </>
);
